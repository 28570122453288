import * as React from "react"
import Main from "../../components/atoms/Main/Main";

import {StaticImage} from "gatsby-plugin-image";
import MainNav from "../../components/molecules/MainNav/MainNav";
import {Link} from "gatsby";

// markup
const IndexPage = () => {
  return (
    <Main footerContent={(
      <MainNav active="spiele"/>
    )}>
      <Link to="/app/paare">
        <StaticImage src="../../images/app/spiele_paare.png" alt="" layout="fullWidth"/>
      </Link>
    </Main>
  )
}

export default IndexPage
